@import url('./rsuite.css');
@import url('./sb-admin-2.css');
@import url('./all.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap"');
@import url('./landing.css');
@import url('./stacks.css');


body {
  font-family: 'Open Sans', sans-serif;
}

.customer-details-body {
  display: flex;
  flex-direction: row; 
  justify-content: flex-start
}

.customer-details-body-card1 {
  display: flex;
  flex-direction: column; 
  justify-content: flex-start
}

.customer-details-body-card1-details {
  display: flex;
  flex-direction: row; 
  justify-content: flex-start
}