.logo {
  width: 87%;
}

.head {
  float: right;
}

.hline {
  border: 0.5px solid;
}

.account {
  float: right;
  padding: 15px;
  font-size: 18px;
}
.name {
  padding: 10px;
}

.menu-collapsed {
  color: white;
}
/* 
.back{
  background-color: blue;
  height:800px;
} */

.tableContainer {
  clear: both;
  border: 1px solid #963;
  overflow: auto;
  height: 400px;
}

.fixed_header {
  width: 400px;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  overflow: auto;
}

.fixed_header thead {
  color: #fff;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}

.sticky_head {
  z-index: 10;
  position: sticky;
  top: 0;
  background-color: white;
}

.sticky_column {
  position: sticky;
  left: 0px;
  background-color: white;
}

.navbro {
  position: sticky;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(219,243,246) !important;;
}

/* .scrollContent {

	height: 450px;
	overflow: auto;
  width: 100%;
  position: relative;
} */

/* .fixedHeader {
	display: table;
	overflow: auto;
	width: 100%
}  */
/* thead th{
  width: 30px
}

tbody td{
  width: 30px
} */

.react-datepicker-popper {
  z-index: 110 !important;
}

.hidden {
  display: none;
}

.error-msg {
  margin-top: 20px;
  color: red;
}
.selectedLink {
  background: white;
  color: 'white';
}
.selectedCollapseLink {
  background: lightgrey;
  color: black;
}

.selectedDropDown {
  background: white;
}
.no-data-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  color: #858796;
}

.no-data-td {
  border: 1px solid #000000;
  text-align: left;
  padding: 8px;
  font-size: small;
}
.table-Height {
  height: 500px;
}
.btn-stockal {
  color: #fff !important;
  background-color: #0061D3 !important;
  border-color: #0061D3 !important;
}

.plan-panel {
  display: inline-block;
  height: 10px;
  width: 30px;
  vertical-align: middle;
  margin-left: 18px;
  margin-right: 5px;
  border: 1px solid black;
}

.div-paginaton {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.pagination-record {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4e73df;
  background-color: #fff;
  border: 1px solid #dddfeb;
}

.number-pages {
  margin-right: 10px;
  margin-bottom: 7px;
  width: auto !important;
  background-color: rgb(143, 172, 197);
}
