.rdg-cell-expand {
    float: right;
    display: table;
    height: 100%;
  }
  
  .rdg-cell-expand > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
  }
  
  .rdg-child-row-action-cross:before,
  .rdg-child-row-action-cross:after {
    content: "";
    position: absolute;
    background: grey;
  }
  
  .rdg-child-row-action-cross:before {
    left: 21px;
    width: 1px;
    height: 100%;
  }
  
  .rdg-child-row-action-cross:after {
    top: 50%;
    left: 20px;
    height: 1px;
    width: 15px;
  }
  
  .rdg-child-row-action-cross:hover {
    background: red;
  }
  .rdg-child-row-btn {
    cursor: pointer;
    position: absolute;
    left: 21px;
    transform: translateX(-50%);
    filter: grayscale(1);
  }