.landing-header-btn {
    padding: 5px 40px 5px 40px;
    margin: 0px 30px 0px 0px;
    background-color: #f8f9fc!important;
}


.landing-dropdown-menu {
    transform: translate3d(0px, 0px, 0px)!important;
    display: flex!important;
}

.datePickerExtension {
    margin-right: 20px!important;
}


.selectedActive {
    color: white!important;
    background-color: #6439b6!important;
}

.color-black {
    color: black;
}

.splitView {
    width: 49%;
    float: left;
    font-size: 13px;
}